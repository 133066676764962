import { Avatar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { DataGrid } from "../../components/core-sub/DataGrid";
import { Container } from "../../components/core-sub/Container";
import { ContentHeader } from "../../components/core-sub/ContentHeader";
import { useCore } from "../../components/core-sub/context";
import { KuiActionIcon } from "../../components/core-sub/KuiActionIcon";
import { KuiButton } from "../../components/core-sub/KuiButton";
import MainContainer from "../../components/core-sub/MainContainer";
import { usePopup } from "../../components/core-sub/Popup";
import { VisibilityValue } from "../../components/core-sub/Visibility";
import { VisibilityTabs } from "../../components/core-sub/VisibilityTabs";
import { Post } from "../../components/core-sub/Controller/post";
import { PhrainNest } from "../../components/core-sub/Controller/phrain.nest";
import { PickIcon } from "../../components/core-sub/PickIcon";

export const PagePostManager = () => {
  const { user, t } = useCore();
  const { Popup } = usePopup();
  const nav = useNavigate();
  const [tab, setTab] = useState<VisibilityValue>("public");
  const [post, setPost] = useState<{
    loading: boolean;
    docs: Post[];
    token: null | string;
  }>({
    loading: true,
    docs: [],
    token: null,
  });
  const handleRestore = (post: Post) => async () => {
    await post.update("visibility", "private");
  };
  const handleRemoveFromTrash = (post: Post) => () => {
    Popup.remove({
      title: t("Remove"),
      text: t("Do You Want To Remove $Name Forever", { name: post.title }),
      icon: "trash",
      onConfirm: async () => {
        if (user.data) {
          await post.remove();
        }
      },
    });
  };
  const handleAddPost = () => {
    Popup.prompt({
      title: t("Create $Name", { name: t("POST") }),
      text: t("Title"),
      icon: "plus-circle",
      onConfirm: async (title) => {
        if (title && user.data) {
          const doc = await Post.add(user.data.uid, title);
          setTab("private");
          if (doc.id) {
            Popup.confirm({
              title: t("Created"),
              text: t("Ready To Edit", { name: title }),
              icon: "check-circle",
              onConfirm: () => {
                nav(`/post/editor/${doc.id}`);
              },
            });
          }
        }
      },
    });
  };
  const handleRemovePost = (post: Post) => () => {
    Popup.remove({
      title: t("Remove"),
      text: t("Do You Want To Remove $Name", { name: post.title }),
      icon: "trash",
      onConfirm: async () => {
        if (user.data) {
          await post.update("visibility", "trash");
        }
      },
    });
  };

  useEffect(() => {
    if (user.loading === false) {
      if (user.data) {
        user.data
          .getIdToken()
          .then((token) => setPost((s) => ({ ...s, token })));
        return Post.watchMy(user.data, (docs) =>
          setPost((s) => ({ ...s, loading: false, docs }))
        );
      } else {
        setPost((s) => ({ ...s, loading: false, docs: [], token: null }));
      }
    } else {
      setPost((s) => ({ ...s, loading: true, docs: [], token: null }));
    }
    return () => {};
  }, [user, t]);

  return (
    <MainContainer maxWidth="md" signInOnly>
      <Container maxWidth="md">
        <ContentHeader
          label={t("Manage $Name", { name: t("POST") })}
          breadcrumbs={[
            { label: t("Home") },
            {
              label: t("Manage $Name", { name: t("POST") }),
            },
          ]}
          actions={<KuiButton tx="add" onClick={handleAddPost} />}
        />
        <VisibilityTabs value={tab} onChange={setTab} items={post.docs} />
        <DataGrid
          initialState={{
            sorting: { sortModel: [{ field: "datemodified", sort: "desc" }] },
          }}
          loading={post.loading}
          rows={Post.filter(post.docs, tab)}
          columns={[
            {
              field: "action",
              headerName: " ",
              width: 64,
              renderCell: ({ row }) => {
                switch (tab) {
                  case "public":
                  case "private":
                    return (
                      <>
                        <KuiActionIcon
                          tx="edit"
                          onClick={() => nav(`/post/editor/${row.id}`)}
                        />
                        <KuiActionIcon
                          tx="remove"
                          onClick={handleRemovePost(row)}
                        />
                      </>
                    );
                  case "trash":
                    return (
                      <>
                        <KuiActionIcon
                          tx="restore"
                          onClick={handleRestore(row)}
                        />
                        <KuiActionIcon
                          tx="remove"
                          onClick={handleRemoveFromTrash(row)}
                        />
                      </>
                    );
                  default:
                    return null;
                }
              },
              filterable: false,
              sortable: false,
              align: "center",
            },
            {
              field: "feature",
              headerName: " ",
              renderCell: ({ row }) => (
                <Avatar
                  variant="square"
                  src={PhrainNest.imageSrcFromId(
                    row?.feature?.image?._id,
                    post.token
                  )}
                >
                  <PickIcon icon="image" />
                </Avatar>
              ),
              width: 60,
              align: "center",
            },
            {
              field: "title",
              headerName: t("Title"),
              renderCell: ({ row }) => (
                <Typography
                  variant="body2"
                  sx={{
                    color: "info.main",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    nav(`/post/preview`, {
                      state: { data: row, back: `/` },
                    })
                  }
                >
                  {row.title}
                </Typography>
              ),
              width: 360,
            },
            {
              field: "datemodified",
              headerName: t("Date"),
              renderCell: ({ value }) => moment(value).format("l LT"),
              width: 180,
            },
          ]}
        />
      </Container>
    </MainContainer>
  );
};
