import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCore } from "../../components/core-sub/context";
import { PageDoc } from "../../components/core-sub/Controller/page";
import { Post } from "../../components/core-sub/Controller/post";
import { simpleHash } from "../../components/core-sub/func";
import { PageEdit } from "../../components/core-sub/PageEdit";

export const PagePostEditor = () => {
  const { postId } = useParams();
  const { user, t } = useCore();
  const nav = useNavigate();
  const location = useLocation();
  const [state, setState] = useState<{ hash: string; data: PageDoc }>({
    hash: "",
    data: new PageDoc(),
  });

  const handleSave = async () => {
    if (postId && user.data) {
      setState((s) => ({ ...s, hash: simpleHash(JSON.stringify(state.data)) }));
      const post = new Post({ ...state.data, id: postId, user: user.data.uid });
      await post.save();
    }
    return true;
  };

  useEffect(() => {
    if (user.loading === false && user.data && postId) {
      if (location.state?.data instanceof Post) {
        setState((s) => ({
          ...s,
          data: location.state.data,
          hash: simpleHash(JSON.stringify(location.state.data)),
        }));
      } else {
        Post.getOne(user.data.uid, postId).then((post) => {
          setState((s) => ({
            ...s,
            data: post ?? new PageDoc(),
            hash: simpleHash(JSON.stringify(post ?? new PageDoc())),
          }));
        });
      }
    } else {
      setState((s) => ({
        ...s,
        data: new PageDoc(),
        hash: simpleHash(JSON.stringify(new PageDoc())),
      }));
    }
  }, [user, postId, location.state]);

  return (
    <>
      <PageEdit
        back={`/`}
        breadcrumbs={[
          { label: t("Home"), to: "/" },
          {
            label: t("Manage $Name", { name: t("POST") }),
            to: "/",
          },
          { label: t("Edit $Name", { name: t("POST") }) },
        ]}
        data={state.data}
        setData={(data) => setState((s) => ({ ...s, data }))}
        show={[
          "heading",
          "image",
          "paragraph",
          "video",
          "table",
          "visibility",
          "title",
          "feature",
          "divider",
          "file",
          "maps",
        ]}
        onSave={handleSave}
        maxWidth={"post"}
        onPreview={() =>
          nav(`/post/preview`, {
            state: {
              data: state.data,
              back: `/post/editor/${postId}`,
            },
          })
        }
        hash={state.hash}
      />
    </>
  );
};
