import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ListItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb } from "../../components/core-sub/ContentHeader";
import { useCore } from "../../components/core-sub/context";
import { PageDoc } from "../../components/core-sub/Controller/page";
import MainContainer from "../../components/core-sub/MainContainer";
import { PageViewer } from "../../components/core-sub/PageViewer";

export const PostPreview = () => {
  const { t } = useCore();
  const [state, setState] = useState<{
    data?: PageDoc;
    back?: string;
    breadcrumbs?: Breadcrumb[];
  }>({});
  const localtion = useLocation();
  const nav = useNavigate();

  useEffect(() => {
    if (localtion.state.data) {
      if (localtion.state.data instanceof PageDoc) {
        setState((s) => ({
          ...s,
          data: localtion.state.data,
          back: localtion.state.back,
          breadcrumbs: localtion.state.breadcrumbs,
        }));
      }
    }
  }, [localtion]);

  return state.data ? (
    <MainContainer
      dense
      sidebar={
        state.back && (
          <ListItem divider>
            <Button
              startIcon={<FontAwesomeIcon icon={["far", "chevron-left"]} />}
              color="neutral"
              onClick={() => nav(state.back!, { state: { data: state.data } })}
            >
              {t("Back")}
            </Button>
          </ListItem>
        )
      }
    >
      <PageViewer
        noContainer
        data={state.data}
        breadcrumbs={state.breadcrumbs}
      />
    </MainContainer>
  ) : (
    <MainContainer
      restrict
      restrictProps={{
        message: "Post not found",
        icon: "file-slash",
        link: "/",
      }}
    />
  );
};
